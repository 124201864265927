export class User {
    IdUser: string;
    Name: string;
    Email: string;
    Password: string;
    RecoveryCode: string;

    constructor(IdUser: string, Name: string, Email: string, Password: string, RecoveryCode: string){
        this.IdUser = IdUser;
        this.Name = Name;
        this.Email = Email;
        this.Password = Password;
        this.RecoveryCode = RecoveryCode;
    }
}