<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#ffffff" type="pacman" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="container">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-7">
      <div class="row my-3">
        <div class="col-md-3 text-center">
          <img src="https://cprandfirstaidtraininginstitute.com/assets/img/logo.png" alt="" style="width: 125px;">
        </div>
        <div class="col-md-8 text-center">
          <img src="https://cprandfirstaidtraininginstitute.com/Payments/img/title.PNG" alt=""
            style="margin-top:21px; margin-bottom: 7px;">
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-3"></div>
    <div class="col-12 col-md-6 p-3 rounded shadow" style="background-color: #f0f0f0;" *ngIf="typePayment != 'certificate'">
      <mat-label class="" style="font-size:15px;"><b>Invoice Number</b>: </mat-label>
      <mat-label>{{invoiceNumber}}</mat-label><br>
      <mat-label class="" style="font-size:15px;"><b>Invoice Date</b>: </mat-label>
      <mat-label>{{invoiceDate}}</mat-label><br>
      <mat-label class="" style="font-size:15px;"><b>To</b>: </mat-label>
      <mat-label>{{to}}</mat-label><br>
      <mat-label class="" style="font-size:15px;"><b>From</b>: </mat-label>
      <mat-label>{{from}}</mat-label><br>
      <mat-label class="" style="font-size:15px;"><b>Subtotal</b>: $</mat-label>
      <mat-label>{{subtotal}}</mat-label><br>
      <mat-label class="" style="font-size:15px;"><b>Card payment tax</b>: $</mat-label>
      <mat-label>{{cardTax}}</mat-label><br>
      <mat-label class="" style="font-size:15px;"><b>Total</b>: $</mat-label>
      <mat-label>{{total}}</mat-label><br><br>
      <mat-label style="font-size: 12px;">Payment Information:</mat-label><br>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="example-full-width w-100" appearance="fill">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="" value="" [(ngModel)]="firstname" name="firstname">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width w-100" appearance="fill">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="" value="" [(ngModel)]="lastname" name="lastname">
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width w-100" appearance="fill">
            <mat-label>Address</mat-label>
            <input matInput placeholder="" value="" [(ngModel)]="address1" name="address1">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="example-full-width w-100" appearance="fill">
            <mat-label>City</mat-label>
            <input matInput placeholder="" value="" [(ngModel)]="city" name="city">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="example-full-width w-100" appearance="fill">
            <mat-label>State</mat-label>
            <input matInput placeholder="" value="" [(ngModel)]="state" name="state">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="example-full-width w-100" appearance="fill">
            <mat-label>ZIP</mat-label>
            <input matInput placeholder="" value="" [(ngModel)]="zip" name="zip">
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width w-100" appearance="fill">
            <mat-label>Note:</mat-label>
            <textarea matInput [(ngModel)]="note" name="note"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 p-3 rounded shadow" style="background-color: #f0f0f0;" *ngIf="typePayment == 'certificate'">
      <mat-label class="" style="font-size:15px;"><b>Replacement Certificate</b></mat-label><br>
        <mat-label class="" style="font-size:15px;"><b>Process Fee </b> $</mat-label>
        <mat-label>{{subtotal}}</mat-label><br>
        <mat-label class="" style="font-size:15px;"><b>Card Proccesing Fee 3.5% </b> $</mat-label>
        <mat-label>{{cardTax}}</mat-label><br>
        <mat-label class="" style="font-size:15px;"><b>Total </b>$</mat-label>
        <mat-label>{{total}}</mat-label><br><br>
        <mat-label style="font-size: 12px;">Payment Information:</mat-label><br>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="example-full-width w-100" appearance="fill">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="" value="" [(ngModel)]="firstname" name="firstname">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width w-100" appearance="fill">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="" value="" [(ngModel)]="lastname" name="lastname">
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width w-100" appearance="fill">
            <mat-label>Address</mat-label>
            <input matInput placeholder="" value="" [(ngModel)]="address1" name="address1">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="example-full-width w-100" appearance="fill">
            <mat-label>City</mat-label>
            <input matInput placeholder="" value="" [(ngModel)]="city" name="city">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="example-full-width w-100" appearance="fill">
            <mat-label>State</mat-label>
            <input matInput placeholder="" value="" [(ngModel)]="state" name="state">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="example-full-width w-100" appearance="fill">
            <mat-label>ZIP</mat-label>
            <input matInput placeholder="" value="" [(ngModel)]="zip" name="zip">
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width w-100" appearance="fill">
            <mat-label>Required information to proccess the replacement certificate.</mat-label>
            <textarea matInput [(ngModel)]="note" name="note" placeholder="Registration ID, Email used for your registration, Fullname, etc..."></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-3"></div>
    <div class="col-12 col-md-6 p-3 rounded shadow" style="background-color: #f0f0f0;">
      <div class="row">
        <div class="col-md-12 w-100 my-3">
          <div #paypal></div>
          <button mat-raised-button class="w-100" color="primary" (click)="payment()">Validate and pay</button>
        </div>
      </div>
    </div>
  </div>
</div>