import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServicesService } from 'src/Services.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { stringify } from '@angular/compiler/src/util';
import { NgxSpinnerService } from "ngx-spinner";
import { sha256, sha224 } from 'js-sha256';
import * as forge from 'node-forge';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { PaymentDTO } from './Models/PaymentDTO';
import { User } from './Models/User';
import * as CryptoJS from 'crypto-js';
import { sendEmailDTO } from './Models/sendEmailDTO';

declare let paypal: { Buttons: (arg0: { createOrder: (data: any, actions: any) => any; onApprove: (data: any, actions: any) => Promise<void>; onError: (err: any) => void; }) => { (): any; new(): any; render: { (arg0: any): void; new(): any; }; }; };


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('paypal', { static: true }) paypalElement?: ElementRef;

  wait: boolean = false;
  showSuccess: boolean = false;
  showCancel: boolean = false;
  showError: boolean = false;
  dataVerified: boolean = false;
  invoiceNumber: string = "";
  invoiceDate: string = "";
  to: string = "";
  from: string = "";
  subtotal: number = 0;
  cardTax: number = 0;
  total: string = "";
  closeResult = '';
  webToken: string = "";
  certificateRecoverytTemplateId: string = "d-950f60690b384da6a6b54de697b4fd1c";
  templateId: string = "d-668e24433b484197abc47c20565eb8d6";
  templateAdminId: string = "d-1035fd4026fe480db502acbcd07b390d";
  fromEmail: string = "iv@cprandfirstaidtraininginstitute.com";
  adminEmail: string = "iv@cprandfirstaidtraininginstitute.com";
  titleEmail: string = "Payment Receipt";
  titleEmailCertificate: string  = "Payment Receipt";
  titleEmailAdmin: string = "Payment Receipt - Admin";
  toEmail: string = "hancarcuevas@gmail.com";
  note: string = "";
  typePayment: string = "";
  InfoNameOfTheCompany: string = "";
  InfoAddress: string = "";
  InfoState: string = "";
  InfoCity: string = "";
  InfoZIP: string = "";
  InfoTelephone: string = "";
  InfoEmail: string = "";

  //Payment
  firstname: string = "";
  lastname: string = "";
  address1: string = "";
  city: string = "";
  state: string = "";
  zip: string = "";
  amount: number = 0;
  PaymentInfo: PaymentDTO = new PaymentDTO('', '', '', '', '', '', '', '', '', '');
  _user = new User('', 'F2E78D3A599AAFE67C44514B4945D82E5ECE470DEAB65B21850CA30BF45C27AC', '', 'F4E23A9604DD4C2AFE6142E53B1D743029316C2B7E715D4F52E50CEE422F461D', '');
  continue = false;
  validated = false;
  item = "";
  sendEmailDTO: sendEmailDTO = new sendEmailDTO('', '', '', '', '', '', '', undefined, '',false,undefined);
  constructor(private modalService: NgbModal, private activatedRoute: ActivatedRoute, private webApi: ServicesService, private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.initConfig();
    this.activatedRoute.queryParams.subscribe(params => {
      this.invoiceNumber = params['invoiceNumber'];
      this.invoiceDate = params['invoiceDate'];
      this.to = params['to'];
      this.from = params['from'];
      this.fromEmail = params['fromEmail'];
      this.subtotal = params['subtotal'];
      this.cardTax = params['cardTax'];
      this.total = params['total'];
      this.titleEmail = params['titleEmail'];
      this.toEmail = params['toEmail'];
      this.note = params['note'];
      this.typePayment = params['typePayment'];
      this.lastconfig();
    }, (error: any) => {
      if (typeof error['Errors'] != "undefined") {
        console.log(error['Errors']);
      }
    });

  }
  lastconfig() {
    if (this.typePayment == 'certificate') {
      this.subtotal = 20;
      this.cardTax = Number((this.subtotal * 0.035).toFixed(2));
      this.total = (this.subtotal + this.cardTax).toFixed(2).toString();
      this.item = "Replacement Certificate";
    }
  }

  payment() {
    let verified = false;
    if (this.typePayment == 'certificate') {
      if (this.firstname != "" && this.lastname != "" && this.address1 != "" && this.city != "" && this.state != "" && this.zip != "" && this.note) {
        verified = true;
      }
    } else {
      if (this.firstname != "" && this.lastname != "" && this.address1 != "-" && this.city != "" && this.state != "" && this.zip != "") {
        verified = true;
      }
    }
    if (verified) {
      if(this.validated){
        return;
      }
      if(this.paypalElement != undefined || this.paypalElement != null){

        this.validated = true;
  
        paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.item,
                  amount: {
                    currency_code: 'USD',
                    value: Number(this.total).toFixed(2)
                  }
                }
              ]
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            this.paymentDone();
          },
          onError: (err: any) => {
            Swal.fire("An error has ocurred: " + err, '', 'error');
          }
        })
        .render(this.paypalElement.nativeElement);
      }
  }else {
    Swal.fire(
      'Sorry!',
      'You need to fill out the entire payment form.',
      'info'
    );
  }
}


paymentDone(){
  this.spinner.show();
    if (this.typePayment == 'certificate') {
      this.sendEmailPaymentCertificate();
    } else {
      this.sendEmailPayment();
    }
    this.spinner.hide();
  } 


  sendEmailPayment() {
    this.spinner.show();
    this.webApi.sendEmail(this.webToken, this.fromEmail, this.titleEmail, this.toEmail, this.templateId, this.total.toString()).subscribe((data: any) => {
      this.webToken = data;
      if (this.webToken == "") {
        Swal.fire(
          'Sorry!',
          'Something went wrong trying to send your payment receipt to your email.',
          'error'
        );
      }
    },
      (error) => {
        if (error.status == "200") {
          //this.sendEmailPaymentPaymentInfo();
          Swal.fire(
            'Awesome!',
            'You will reveice an email with your payment receipt.',
            'success'
          );
        } else {
          Swal.fire(
            'Sorry!',
            'Something went wrong trying to send your payment receipt to your email.',
            'error'
          );
        }
      });
    this.spinner.hide();
  }
  sendEmailPaymentCertificate() {
    this.spinner.show();
    this.sendEmailDTO.subject = 'Replacement Certificate';
    this.sendEmailDTO.body = this.note;
    this.sendEmailDTO.to = this.toEmail;
    this.sendEmailDTO.toName = 'Administration';
    this.sendEmailDTO.replyTo = this.adminEmail;
    this.sendEmailDTO.templateId = this.certificateRecoverytTemplateId;
    let templateData: string[] = [];
    templateData.push(this.firstname + ' ' + this.lastname);
    templateData.push(this.subtotal.toFixed(2));
    templateData.push(this.cardTax.toFixed(2));
    templateData.push(this.total);
    templateData.push(this.note);
    this.sendEmailDTO.templateData = templateData;
    this.sendEmailDTO.toCC = this.adminEmail;
    this.sendEmailDTO.hasHtmlBody = false;
    this.sendEmailDTO.parameters = templateData;
    this.webApi.sendEmailCertificate(this.sendEmailDTO).subscribe((data: any) => {
      this.webToken = data;
      if (this.webToken == "") {
        Swal.fire(
          'Sorry!',
          'Something went wrong trying to send your payment receipt to your email.',
          'error'
        );
      }
    },
      (error) => {
        if (error.status == "200") {
          //this.sendEmailPaymentPaymentInfo();
          Swal.fire(
            'Awesome!',
            'You will reveice an email with your payment receipt.',
            'success'
          );
        } else {
          Swal.fire(
            'Sorry!',
            'Something went wrong trying to send your payment receipt to your email.',
            'error'
          );
        }
      });
    this.spinner.hide();
  }
  sendEmailPaymentPaymentInfo() {
    this.spinner.show();
    this.webApi.sendEmailPaymentInfo(this.webToken, this.fromEmail, this.titleEmailAdmin, this.invoiceNumber, this.templateAdminId, this.total.toString(), this.InfoNameOfTheCompany, this.InfoAddress, this.InfoState, this.InfoCity, this.InfoZIP, this.InfoTelephone, this.InfoEmail).subscribe((data: any) => {
      this.webToken = data;
      if (this.webToken == "") {
        Swal.fire(
          'Sorry!',
          'Something went wrong trying to send your payment receipt to your email.',
          'error'
        );
      }
    },
      (error) => {
        if (error.status == "200") {
          Swal.fire(
            'Awesome!',
            'You will reveice an email with your payment receipt.',
            'success'
          );
        } else {
          Swal.fire(
            'Sorry!',
            'Something went wrong trying to send your payment receipt to your email.',
            'error'
          );
        }
      });
    this.spinner.hide();
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason: any) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  private initConfig(): void {

  }
}

function List<T>() {
  throw new Error('Function not implemented.');
}
