export class sendEmailDTO {
    subject: string;
    body: string;
    to: string;
    toName: string;
    replyTo: string;
    replyToName: string;
    templateId: string;
    templateData: string[] = [];
    toCC: string;
    hasHtmlBody: boolean = false;
    parameters: string[] = [];

    constructor(subject: string,
        body: string,
        to: string,
        toName: string,
        replyTo: string,
        replyToName: string,
        templateId: string,
        templateData: string[] = [],
        toCC: string,
        hasHtmlBody: boolean = false,
        parameters: string[] = []){
        this.subject = subject;
        this.body = body;
        this.to = to;
        this.toName = toName;
        this.replyTo = replyTo;
        this.replyToName = replyToName;
        this.templateId = templateId;
        this.templateData = templateData;
        this.toCC = toCC;
        this.hasHtmlBody = hasHtmlBody;
        this.parameters = parameters;
    }
}