import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { environment } from './environments/environment.prod';
import { map } from 'rxjs/operators';
import { PaymentDTO } from './app/Models/PaymentDTO';
import { User } from './app/Models/User';
import { sendEmailDTO } from './app/Models/sendEmailDTO';
@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  
  constructor(private http: HttpClient) { }

  sendEmail(token: string, from: string, title: string, to: string, templateId: string, total: string) {
    const url = `${environment.webApiBase}` + 'api/Services/SendGrid/?from=' + from + '&title=' + title + '&to=' + to + '&templateId=' + templateId + '&total=' + total;
    return this.http.get(url, {
      headers: new HttpHeaders(
        {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
          'Accept': '*/*',
        }),
      //responseType: ResponseContentType.Blob,
    }).pipe(map(data => {
      return data
    }));
  }
  sendEmailCertificate(sendEmailDTO: sendEmailDTO) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Content-Type': 'application/json',
      'Accept': '*/*',
    })
    const url = `${environment.webApiBase}` + 'api/Mail/SendCertificateInformation';

    return this.http.post(url, sendEmailDTO, { headers: headers });
  }
  sendEmailPaymentInfo(token: string, from: string, title: string, invoiceNumber: string, templateId: string, total: string, name: string, address: string, state: string, city: string, zip: string, telephone: string, email: string) {
    const url = `${environment.webApiBase}` + 'api/Services/SendGrid/PaymentInfo/?from=' + from + '&title=' + title + '&invoiceNumber=' + invoiceNumber + '&templateId=' + templateId + '&total=' + total + '&name=' + name + '&address=' + address + '&state=' + state + '&city=' + city + '&zip=' + zip + '&telephone=' + telephone + '&email=' + email;
    return this.http.get(url, {
      headers: new HttpHeaders(
        {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
          'Accept': '*/*',
        }),
      //responseType: ResponseContentType.Blob,
    }).pipe(map(data => {
      return data
    }));
  }
  makePayment(token: string, paymentDTO: PaymentDTO) {

    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Content-Type': 'application/json',
      'Accept': '*/*',
    })
    const url = `${environment.webApiBase}` + 'api/Services/PaymentProcess';

    return this.http.post(url, paymentDTO, { headers: headers });
  }
  sendEmailCertificatev2(token: string, paymentDTO: PaymentDTO) {

    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Content-Type': 'application/json',
      'Accept': '*/*',
    })
    const url = `${environment.webApiBase}` + 'api/Services/PaymentProcess';

    return this.http.post(url, paymentDTO, { headers: headers });
  }
}

