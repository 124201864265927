export class PaymentDTO {
    firstname: string;
    lastname: string;
    address1: string;
    city: string;
    state: string;
    zip: string;
    amount: string;
    t1: string;
    t2: string;
    t3: string;

    constructor(firstname: string, lastname: string, address1: string, city: string, state: string, zip: string, amount: string, t1: string, t2: string, t3: string){
        this.firstname = firstname;
        this.lastname = lastname;
        this.address1 = address1;
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.amount = amount;
        this.t1 = t1;
        this.t2 = t2;
        this.t3 = t3;
    }
}